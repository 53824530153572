import React, { ReactNode, useRef, CSSProperties } from "react";
import "@/styles/main.css";
import Nav from "@/modules/nav";
import Footer from "@/modules/footer";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import Spark from "@/elements/spark";

type LayoutProps = {
  children: ReactNode;
  colour?: string;
  footer: string;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  colour = "#fff",
  footer,
}) => {
  const containerRef = useRef(null);
  return (
    <LocomotiveScrollProvider
      className="layout"
      containerRef={containerRef}
      options={{
        smooth: true,
        lerp: 0.2,
      }}
      watch={[]}
    >
      <div
        data-scroll-container
        ref={containerRef}
        style={{ "--accent": colour } as CSSProperties}
      >
        <Nav />
        <Spark />
        <main>{children}</main>
        <Footer heading={footer} />
      </div>
    </LocomotiveScrollProvider>
  );
};

export default Layout;

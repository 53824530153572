import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { ImageType } from "@/types";
import RightArrow from "@/img/right-arrow.svg";
import staticVideo from "@/helper/static-video";
import { useInView } from "react-intersection-observer";
import ImageElement from "@/helper/static-image";

export type ShowcaseItemType = {
  videoTitle: string;
  title: string;
  description: string;
  link: string;
  image?: ImageType;
  video?: string;
  index: number;
};

const ShowcaseItem: React.FC<ShowcaseItemType> = ({
                                                    videoTitle,
                                                    title,
                                                    description,
                                                    image,
                                                    link,
                                                    video,
                                                    index,
                                                  }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.8,
  });

  let currentVideo;
  if (video) {
    currentVideo = staticVideo(video);
  }

  useEffect(() => {
    if (inView) {
      setIsLoaded(true);
    }
  }, [inView]);

  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  return (
    <div
      className="c-showcase-item__wrapper"
      id={`showcase-item-marker-${index}`}
      ref={ref}
    >
      <div className="c-showcase-item container">
        <div className="c-showcase-item__text">
          <span  className="m-hero__heading-inline line-3 is-inview pb-1">
            {videoTitle}
          </span>
          <a
            className="c-showcase-item__link"
            href={link}
            data-scroll
            data-scroll-repeat
            data-scroll-offset="75%"
            data-scroll-target={`#showcase-item-marker-${index}`}
            target="_blank"
            rel="noopener noreferrer"
          >

            <h3 className="c-showcase-item__heading heading-showcase">
              {title}
            </h3>
            <RightArrow className="c-showcase-item__arrow" />
          </a>
          <p className="c-showcase-item__description body-small">
            {description}
          </p>
        </div>
        <a
          className="c-showcase-item__media-wrapper"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={title}
        >
          <div className="c-showcase-item__media">
            {currentVideo && !isSSR && (
              <ReactPlayer
                url={currentVideo}
                loop={true}
                playing={isLoaded}
                controls={false}
                playsinline={true}
                muted={true}
                width="100%"
                height="100%"
              />
            )}
            {!video && image?.asset && (
              <ImageElement asset={image.asset} alt={image.alt} />
            )}
          </div>
        </a>
      </div>
    </div>
  );
};

export default ShowcaseItem;

import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import ImageElement from "@/helper/static-image";
import TwentyFour from "../img/twenty-four.jpg"



export type Text = {
  workflow1: string;
  workflow2: string;
  workflow3: string;
  workflow4: string;
};

type ServiceListTypes = {
  text: Text[];
};

const WorkFlowItem: React.FC<ServiceListTypes> = ({text }) => (

    <div className="container">

<div className="flex">
    <div className="item">
      {/*<div className="item-text">*/}
      {/*  {text[0].workflow1}*/}
      {/*</div>*/}
      <div className="item-jpg">
        <img
          src={"../image/assets-1.png"}
          alt="Photo of a collaborative discussion at The Being Group offices."
        />
      </div>
    </div>

    <div className="item">
      <div className="item-jpg item-image-top">
        <img
          src={"../image/assets-2.png"}
          alt="Photo of a collaborative discussion at The Being Group offices."
        />
      </div>
      {/*<div className="item-text">*/}
      {/*  {text[1].workflow2}*/}
      {/*</div>*/}
    </div>

    <div className="item ">
      {/*<div className="item-text">*/}
      {/*  {text[2].workflow3}*/}
      {/*</div>*/}
      <div className="item-jpg">
        <img
          src={"../image/assets-3.png"}
          alt="Photo of a collaborative discussion at The Being Group offices."
        />
      </div>
    </div>

    <div className="item">
      {/*<div className="item-text">*/}
      {/*  {text[3].workflow4}*/}
      {/*</div>*/}
      <div className="item-jpg">
        <img
          // className={"item-4"}
          src={"../image/assets-4.png"}
          alt="Photo of a collaborative discussion at The Being Group offices."
        />
      </div>
    </div>

</div>
    </div>
);

export default WorkFlowItem;


import React from "react";
import Layout from "@/layout";
import SeoComponent from "@/modules/seo";
import Services from "@/modules/services";
import Showcase from "@/modules/showcase";
import Banner from "@/modules/banner";
import Hero from "@/modules/hero";
import WorkFlow from "@/modules/work-flow";


type IndexType = {
  pageContext: any;
};

const Index: React.FC<IndexType> = ({ pageContext }) => {
  const {
    title,
    description,
    keywords,
    accentColour,
    hero,
    services,
    workflow,
    banner,
    showcase,
    footer,
  } = pageContext;
  return (
    <Layout colour={accentColour} footer={footer}>
      <SeoComponent
        title={title}
        description={description}
        keywords={keywords}
      />
      <Hero
        lineOne={hero.lineOne}
        lineTwo={hero.lineTwo}
        lineThree={hero.lineThree}
        lineFour={hero.lineFour}
        cta={hero.cta}
      />
      <Services
        headerVideo={services.headerVideo}
        sectionHeading={services.sectionHeading}
        intro={services.intro}
        heading={services.heading}
        services={services.services}
      />
      <WorkFlow
        header={workflow.header}
        text={workflow.text}
      />
      <Banner text={banner} />
      <Showcase showcaseItems={showcase.items} />
    </Layout>
  );
};

export default Index;

import React from "react";

type IntroProps = {
  sectionHeading?: string;
};

const IntroWorkFlow: React.FC<IntroProps> = ({ sectionHeading }) => (
  <div
    className="c-work-flow-item work-flow-intro-header container "
  >
      <p className="c-work-flow-header__1">{sectionHeading}</p>

    {/*<p className="e-intro__text">{text}</p>*/}
  </div>
);

export default IntroWorkFlow;

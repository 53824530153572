import Jumbled from "@/elements/jumbled";
import React, {useEffect, useState} from "react";
import HeroLine from "@/img/hero-line.svg";
import RightArrow from "@/img/right-arrow.svg";
import ImageElement from "@/helper/static-image";
import ReactPlayer from "react-player";
import {useInView} from "react-intersection-observer";
import staticVideo from "@/helper/static-video";


type HeroType = {
  lineOne: string;
  lineTwo: string;
  lineThree: string;
  lineFour: string;
  cta?: string;
  // video?: string;
};

const Hero: React.FC<HeroType> = ({ lineOne, lineTwo, lineThree,lineFour, cta }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.8,
  });

  let currentVideo;
  let video = "landingPage";

  if (video) {
    currentVideo = staticVideo(video);
  }

  useEffect(() => {
    if (inView) {
      setIsLoaded(true);
    }
  }, [inView]);

  // const [isSSR, setIsSSR] = useState(true);
  //
  // useEffect(() => {
  //   setIsSSR(false);
  // }, []);
  console.log(currentVideo);
  return (
    <section
      className="m-hero__wrapper dark"
      data-scroll-section
      data-scroll
      id="hero-marker"
    >
      <div className="m-hero container" ref={ref}>
        <div className="m-hero__text">
          <h2 className="m-hero__heading heading-large">
            <span>
              <span
                className="m-hero__heading-inline"
                data-scroll
                data-scroll-speed="4"
                data-scroll-target="#hero-marker"
              >
                {`${lineOne} \n`}
              </span>
            </span>
            <span>
              <span
                className="m-hero__heading-inline"
                data-scroll
                data-scroll-speed="5"
                data-scroll-target="#hero-marker"
              >
                {lineTwo}
              </span>
            </span>
            {/*<Jumbled word={lineTwo} speed={4} target="#hero-marker" />*/}
            <span>
              <span
                className="m-hero__heading-inline"
                data-scroll
                data-scroll-speed="5"
                data-scroll-target="#hero-marker"
              >
                {lineThree} <b>{lineFour}</b>
              </span>
            </span>
          </h2>
          <>
            {cta && (
              <div className="m-hero__link-wrapper ">
                <a
                  className="m-hero__link underline -hover-underline"
                  href="#banner-marker"
                  data-scroll
                  data-scroll-speed="4"
                  data-scroll-target="#hero-marker"
                  data-scroll-to="#banner-marker"
                >
                  {cta}
                </a>

                <RightArrow
                  className="m-hero__link-icon"
                  data-scroll
                  data-scroll-speed="4"
                  data-scroll-target="#hero-marker"
                />
              </div>
            )}
          </>
        </div>
        <div className="m-hero__media-wrapper">
          <div
            className="m-hero__media"
            data-scroll
            data-scroll-speed="-3"
            data-scroll-target="#hero-marker"
          >
            {/*<ImageElement*/}
            {/*  asset="studio"*/}
            {/*  alt="Photo of a collaborative discussion at The Being Group offices."*/}
            {/*/>*/}
            <ReactPlayer
              url={currentVideo}
              loop={true}
              playing={isLoaded}
              controls={false}
              playsinline={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
      <HeroLine
        className="m-hero__line"
        data-scroll
        data-scroll-target="#hero-marker"
      />
    </section>
  );
};

export default Hero;

import React from "react";

type IntroProps = {
  sectionHeading?: string;
  text: string;
  headerVideo: string
};

const Intro: React.FC<IntroProps> = ({ sectionHeading, text,headerVideo }) => (
  <div
    className="e-intro "
    data-scroll
    data-scroll-speed="1.1"
    data-scroll-target="#services-marker"
  >
    <h2 className="e-intro__heading heading-showcase">{headerVideo}</h2>

    <div
      className="c-showcase-item__wrapper"
    >
      <div className="c-showcase-item single-vid__wrapper">
        <div
          className=" c-single-vid"
        >

          {/*<script src="https://player.vimeo.com/api/player.js"></script>*/}
          <div className="c-showcase-item__media" id="single-vid">
            {/*<div className="vimeo-overlay"></div>*/}

            <div className={"vimeo-single-vid"}>
              {/*<iframe*/}
              {/*  // src="https://player.vimeo.com/video/528635705?h=7bcb1c921d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"*/}
              {/*  // src="https://player.vimeo.com/video/525340549?h=cffa3c0643&amp;badge=0&amp;autopause=0&autoplay=1&amp;player_id=0&amp;app_id=58479"*/}
              {/*  src="https://player.vimeo.com/video/781716883?h=daae232c33&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"*/}
              {/*  // frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen*/}
              {/*  // className={"iframe-single-vid"} title="BEING_Banner_2021" id={"allow-pointer"}*/}
              {/*  frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen*/}
              {/*  width="632" height="700" className={"iframe-single-vid"}*/}
              {/*  title="The Being Group - The Story of our Brand"*/}
              {/*></iframe>*/}
              {/*<iframe*/}
              {/*  src="https://www.youtube.com/embed?v=towPW8HAby8"*/}
              {/*    frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen*/}
              {/*    width="632" height="700" className={"iframe-single-vid"}*/}
              {/*  title="The Being Group - The Story of our Brand"*/}
              {/*></iframe>*/}
              <iframe width="560" height="315" src="https://www.youtube.com/embed/towPW8HAby8"
                      title="YouTube video player" frameBorder="0"
                      className={"iframe-single-vid"}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen></iframe>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
);

export default Intro;

import equiptec from "@/vid/equiptec.mp4";
import howlingMoon from "@/vid/howling-moon.mp4";
import nsw from "@/vid/nsw.mp4";
import merch from "@/vid/merch.mp4";
import nextGen from "@/vid/next-gen.mp4";
import hitachi from "@/vid/hitachi.mp4";
import sisv from "@/vid/SISV.mp4";
import dinnerly from "@/vid/Dinnerly.mp4";
import landingPage from "@/vid/landing-page.mp4";
import ampol from "@/vid/Ampol.mp4";
import hypersonix from "@/vid/Hypersonix.mp4";
import emv from "@/vid/EMV.mp4";
import pop from "@/vid/POP.mp4";



const staticVideo = (video: string) => {
  let newVideo = video;
  const videoObject = {
    howlingMoon,
    nsw,
    equiptec,
    merch,
    hitachi,
    nextGen,
    sisv,
    dinnerly,
    landingPage,
    emv,
    pop,
    ampol,
    hypersonix
  };
  newVideo = videoObject[video] ? videoObject[video] : video;
  return newVideo;
};

export default staticVideo;

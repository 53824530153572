import React from "react";
import WorkFlowItem, { Text } from "@/components/work-flow-item";
import IntroWorkFlow from "@/elements/intro-work-flow";

type ServicesType = {
  header?: string;
  text: Text[];
};

const WorkFlow: React.FC<ServicesType> = ({
                                            header,
                                            text,
                                          }) => {
  return (
    <section
      className="m-work-flow__container "
      data-scroll-section
      id=""
    >
      <div id="showcase-marker" />
      <div
        className="c-work-flow-item__wrapper underline"
        data-scroll
        data-scroll-offset="75%"
        data-scroll-target="#services-marker"
      >
        <div className="">

          <IntroWorkFlow sectionHeading={header}/>
          <WorkFlowItem text={text} />
        </div>
      </div>

    </section>
  );
};

export default WorkFlow;

import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { ImageType } from "@/types";

const ImageElement: React.FC<ImageType> = ({ asset, alt }) => (
  <>
    {asset === "placeholder" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/placeholder.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
      />
    )}
    {asset === "studio" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/studio.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
      />
    )}
    {asset === "multilit" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/multilit.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
      />
    )}
  </>
);

export default ImageElement;
